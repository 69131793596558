import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Box, Typography } from "@mui/material";
import { EffectCoverflow, Autoplay, Pagination } from "swiper/modules";
import "./FamilyMember.css";
import { FAMILY_MEMBERS_DATA } from "../Constant/constants";

export default function FamilyMember() {
  return (
    <>
      <Box backgroundColor="#fff">
        <Typography variant="h6" fontFamily="Satisfy" textAlign="center">
          Family Members
        </Typography>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="mySwiper"
        >
          {FAMILY_MEMBERS_DATA.map((member) => (
            <SwiperSlide key={member.id}>
              <img src={member.image} alt={member.name} />
              <Typography textAlign="center">{member.name}</Typography>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
}
