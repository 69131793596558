import React from "react";
import Box from "@mui/material/Box";
import MarriageInfo from "./MarriageInfo";
import FamilyMember from "./FamilyMember";
import Functions from "./Functions";
import Wishes from "./Wishes";
import Location from "./Location";
import Footer from "./Footer";
import BrideGroom from "./BrideGroom";
import QueryPopup from "./QueryPopup";

function Home() {
  return (
    <>
      <Box>
        <MarriageInfo />
        <BrideGroom />
        <Functions />
        <Wishes />
        <FamilyMember />
        <Location />
        <Footer />
        <QueryPopup />
      </Box>
    </>
  );
}

export default Home;
