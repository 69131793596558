
import React from 'react';

function Functions() {
  return (
    <div>
      <h2>Functions</h2>
    </div>
  );
}

export default Functions;
