import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { BOY_DETAILS, GIRL_DETAILS } from "../Constant/constants";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "5rem",
  padding: "50px",
});

const BrideGroom = styled("div")({
  position: "relative",
  fontFamily: "cursive",
  width: "300px",
  height: "260px",
  borderRadius: "10px",
  boxShadow: "0 0 20px #3336",
  margin: "0 25px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(to bottom, rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url('bg-texture.jpg')",
});

const StyledImg = styled("div")({
  width: "70%",
  height: "85%",

  transform: "translateY(-10%)",

  "& img": {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    objectFit: "cover",
  },
});

const StyledCaption = styled("div")({
  textAlign: "center",
  padding: "0px 0",
  fontWeight: "800",
});

const BrideGroomCard = () => {
  return (
    <Container>
      <BrideGroom>
        <StyledImg>
          <img src="dlodha.jpg" alt="Deepak Lodha" />
        </StyledImg>

        <StyledCaption></StyledCaption>
        <Typography variant="h6">{BOY_DETAILS.NAME}</Typography>
      </BrideGroom>

      <BrideGroom>
        <StyledImg>
          <img src="dlodha.jpg" alt="Shivani Lodha" />
        </StyledImg>

        <StyledCaption>
          <Typography variant="h6">{GIRL_DETAILS.NAME}</Typography>
        </StyledCaption>
      </BrideGroom>
    </Container>
  );
};

export default BrideGroomCard;
