// src/components/Contact.js
import React from 'react';
import { Button } from '@mui/material';

function Contact() {
  return (
    <div>
      <h2>Contact</h2>
      <p>Connect with us on social media:</p>
      <Button variant="contained" color="primary" href="https://www.instagram.com/">Instagram</Button>
      <Button variant="contained" color="primary" href="https://www.facebook.com/">Facebook</Button>
      <Button variant="contained" color="primary" href="https://www.linkedin.com/">LinkedIn</Button>
      <p>Email: wedding@example.com</p>
    </div>
  );
}

export default Contact;
