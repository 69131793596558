import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CALENDAR_EVENT_DATA, TARGET_DATE } from "../Constant/constants";
import Counter from "./Counter";
import moment from "moment";
import { AddToCalendarButton } from "add-to-calendar-button-react";

const MarriageInfo = () => {
  const formattedDate = moment(TARGET_DATE).format("DD MMMM YYYY");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Card
          sx={{
            width: "100%",
            height: "20%",
            backgroundImage: "url(hand.jpg)",
            backgroundSize: "cover",
            color: "white",
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontFamily: "Rethink Sans, sans-serif" }}
            >
              Deepak & Shivani
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontFamily: "Rethink Sans, sans-serif" }}
            >
              {formattedDate}
            </Typography>
            <AddToCalendarButton
              label={CALENDAR_EVENT_DATA.label}
              name={CALENDAR_EVENT_DATA.name}
              startDate="2024-04-27"
              endDate="2024-04-29"
              startTime="09:00 AM"
              endTime="05:59 AM"
              timeZone={CALENDAR_EVENT_DATA.timeZone}
              location={CALENDAR_EVENT_DATA.location}
              description={CALENDAR_EVENT_DATA.description}
              options={CALENDAR_EVENT_DATA.options}
              buttonStyle={CALENDAR_EVENT_DATA.buttonStyle}
              buttonsList
              lightMode="bodyScheme"
              size="1"
              hideBackground
              styleLight="--btn-background: #F8F9F9; --btn-text: #273746; --font: Rethink Sans, sans-serif;"
              styleDark="--btn-background: #000;"
              // hideTextLabelButton
              // options={["Google", "Apple"]}
            />
          </CardContent>
        </Card>
      </Box>

      <Box
        sx={{
          backgroundColor: "transparent",
          paddingBottom: "30px",
        }}
      >
        <Counter targetDate={TARGET_DATE.getTime()} />
      </Box>
    </>
  );
};

export default MarriageInfo;
