import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";

const PopupContainer = styled("div")({
  position: "fixed",
  bottom: "20px",
  right: "20px",
  background: "#fff",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "200px",
  height: "200px",
  transition: "transform 0.3s ease-in-out",
});

const CloseButton = styled("button")({
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "14px",
  color: "#007bff",
  fontWeight: "bold",
});

const QueryPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 90000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <PopupContainer>
          <div>
            <p>How can we assist you today?</p>
          </div>
          <CloseButton onClick={handleClose}>Close</CloseButton>
        </PopupContainer>
      )}
    </>
  );
};

export default QueryPopup;
