import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MusicPlayer from "./MusicPlayer";
import { SONG } from "../Constant/constants";
import { useState } from "react";
import { styled } from "@mui/system";

const settings = [
  "BrideGroom",
  "Functions",
  "Wishes",
  "FamilyMember",
  "Address",
];

const VivahImg = styled("div")({
  width: "100%",
  height: "100%",

  "& img": {
    width: "80%",
    height: "80%",
    objectFit: "cover",
  },
});

const DecorImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  marginTop: "2.5rem",
});

function NavBar() {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="7vh"
      >
        <Box m={1}>
          <IconButton>
            <MusicPlayer song={SONG} />
          </IconButton>
        </Box>

        <Box m={1}>
          <DecorImage src="decor2.png" alt="decor" />
        </Box>

        <Box m={1}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <VivahImg>
              <img src="kalash.png" alt="Shubh Vivah" />
            </VivahImg>
          </IconButton>
          <Menu
            sx={{ mt: "2.5rem" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
    </AppBar>
  );
}

export default NavBar;
