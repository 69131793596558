import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { FOOTER_ICONS_DATA } from "../Constant/constants";

export const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "#021c33",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography color="white">{`©${new Date().getFullYear()} - SDK Production`}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            gap={1}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {FOOTER_ICONS_DATA.map((icon) => (
              <a
                key={icon.id}
                href={icon.link}
                style={{ textDecoration: "none" }}
              >
                <img
                  src={icon.url}
                  alt={icon.alt}
                  style={{ width: "20px", height: "20px" }}
                />
              </a>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
