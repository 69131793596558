import React from "react";
import { Box, Typography } from "@mui/material";
import { MAP } from "../Constant/constants";

function Location() {
  return (
    <div>
      <Box backgroundColor="#fff">
        <Typography variant="h6" fontFamily="Satisfy" textAlign="center">
          Location
        </Typography>
        <Typography fontFamily="Satisfy" textAlign="center">
          Dwarka Residency Awan, Raghogarh, Guna, (Madhya Pradesh)
        </Typography>
        <Box marginLeft={1} marginRight={1}>
          <iframe
            title="Location Map"
            src={MAP}
            width="100%"
            height="200"
            style={{ border: "1" }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Box>
      </Box>
    </div>
  );
}

export default Location;
