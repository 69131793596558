// src/components/AudioPlayer.js
import React, { useState, useEffect } from "react";
import "./MusicPlayer.css";

const AudioPlayer = ({ song }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audio = new Audio(`/assets/${song}`);

    if (isPlaying) {
      audio.play().catch((error) => {
        // Autoplay was prevented. Handle the error, and consider asking the user to interact.
        console.error("Autoplay was prevented:", error);
      });
    } else {
      audio.pause();
    }

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [isPlaying, song]);

  return (
    <div className={`audio-player ${isPlaying ? "playing" : ""}`}>
      <audio controls style={{ display: "none" }} autoPlay={false}>
        <source src={`/assets/${song}`} type="audio/mp3" />
      </audio>
      <div onClick={handlePlayPause} className="play-pause-container">
        <img
          src="/sdk.png"
          alt=""
          className={`play-pause-icon ${isPlaying ? "playing" : ""}`}
        />
      </div>
    </div>
  );
};

export default AudioPlayer;
