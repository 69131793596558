// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DateComponent from "./Components/Date";
import LocationComponent from "./Components/Location";
import NavBar from "./Components/Navbar";
import Home from "./Components/Home";
import Wishes from "./Components/Wishes";
import Contact from "./Components/Contact";
import { createTheme, ThemeProvider } from "@mui/material";
import "./App.css"

const theme = createTheme({
  typography: {
    fontFamily: ["Noto Sans", "Poppins", "Rethink Sans", "sans-serif"].join(
      ","
    ),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <NavBar />
        <Home />
        <Routes>
          <Route path="/" exact component={Home} />
          <Route path="/date" component={DateComponent} />
          <Route path="/location" component={LocationComponent} />
          <Route path="/wishes" component={Wishes} />
          <Route path="/contact" component={Contact} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
