export const TARGET_DATE = new Date("2024-04-28T11:00:00");

export const SONG = "mere_yar_ki_shadi_hai.mp3";

export const BOY_DETAILS = {
  NAME: "DEEPAK LODHA",
  ADDRESS: "Awan, Raghogarh, Guna (M.P)",
};

export const GIRL_DETAILS = {
  NAME: "SHIVANI LODHA",
  ADDRESS: "Guara, Ruthiyai, Guna (M.P)",
};

export const CALENDAR_EVENT_DATA = {
  label: "Save The Date",
  name: "[Reminder] Deepak's marriage invitation",
  startDate: "2024-04-27",
  endDate: "2024-04-29",
  startTime: "9:00 AM",
  endTime: "05:00 AM",
  timeZone: "Asia/Kolkata",
  location: "Dwarka Residency Awan, Raghogarh, Guna, (Madhya Pradesh)",
  description: "Hi, You are invited to attend marriage function at our home.",
  options: "'Google','Apple'",
  buttonStyle: "round",
};

export const MAP =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7256.947994036033!2d77.14133!3d24.408954!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397affa2c1c8b515%3A0xc07c4cfa65b606f3!2sDwarka%20Residency!5e1!3m2!1sen!2sin!4v1702664216307!5m2!1sen!2sin";

export const FAMILY_MEMBERS_DATA = [
  {
    id: 1,
    name: "John Doe",
    image:
      "https://img.freepik.com/premium-vector/indian-man-face-avatar_710508-12.jpg?w=360",
  },
  {
    id: 2,
    name: "Jane Doe",
    image:
      "https://img.freepik.com/premium-vector/indian-man-face-avatar_710508-12.jpg?w=360",
  },
  {
    id: 3,
    name: "Alice",
    image:
      "https://img.freepik.com/premium-vector/indian-man-face-avatar_710508-12.jpg?w=360",
  },
  {
    id: 4,
    name: "Bob",
    image:
      "https://img.freepik.com/premium-vector/indian-man-face-avatar_710508-12.jpg?w=360",
  },
  {
    id: 5,
    name: "Eva",
    image:
      "https://img.freepik.com/premium-vector/indian-man-face-avatar_710508-12.jpg?w=360",
  },
  {
    id: 6,
    name: "Michael",
    image:
      "https://img.freepik.com/premium-vector/indian-man-face-avatar_710508-12.jpg?w=360",
  },
  {
    id: 7,
    name: "Olivia",
    image:
      "https://img.freepik.com/premium-vector/indian-man-face-avatar_710508-12.jpg?w=360",
  },
  {
    id: 8,
    name: "David",
    image:
      "https://img.freepik.com/premium-vector/indian-man-face-avatar_710508-12.jpg?w=360",
  },
  {
    id: 9,
    name: "Sophia",
    image:
      "https://img.freepik.com/premium-vector/indian-man-face-avatar_710508-12.jpg?w=360",
  },
];

export const FOOTER_ICONS_DATA = [
  {
    id: 1,
    url: "fb.png",
    link: "https://www.facebook.com/deepaklodha0",
  },
  {
    id: 2,
    url: "insta.png",
    link: "https://www.instagram.com/sdkosm/",
  },
  {
    id: 3,
    url: "linkedin.png",
    link: "https://www.linkedin.com/in/deepaklodha/",
  },
];
